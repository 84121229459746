import { graphql, Link } from "gatsby";
import * as React from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import Recaptcha from "react-google-recaptcha";
import { BigPlayButton, Player, LoadingSpinner } from "video-react";
import "video-react/dist/video-react.css";
import { NotificationSuccess } from "../components/Helper";
import Layout from "../components/Layout";

export default function BogoBusinessPage({ data }) {
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const BusinessItem = data.BusinessItem.nodes;
  const BogoImageWidget = data.BogoImageWidget.nodes;
  const BogoStep = data.BogoStep.nodes;
  const BogoBrochure = data.BogoBrochure.nodes;
  const recaptchaRef = React.createRef();

  const [formState, setFormState] = React.useState({
    firstname: "",
    email: "",
  });
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Contact-client",
        "g-recaptcha-response": recaptchaValue,
        ...formState,
      }),
    })
      .then(() => NotificationSuccess({ message: "Thank you" }))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  return (
    <Layout>
      <main>
        <Container>
          <section className="bogobusiness d-none d-sm-block">
            <div className="col-xl-12 col-lg-12 col-md-12 p-5 busines-separator">
              <div className="col-xl-5 col-lg-5 card-body"></div>
              <div className="col-xl-11 col-lg-11 col-md-12 mx-auto">
                <Row>
                  <div className="col-xl-6 col-lg-6 col-md-12  pt-4 pe-5 pb-3">
                    <img
                      src="../img/Bogo-About.svg"
                      alt="Logo About"
                      height="56"
                      width="96"
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12  py-3 align-self-center">
                    <h4 className="fw-bold fs-24">Other Incentive Apps</h4>
                  </div>
                </Row>
              </div>
              {BusinessItem.map((node, index) => {
                return (
                  <>
                    {node?.BusinessItems?.length > 0 &&
                      node.BusinessItems.map((node2) => (
                        <div className="col-xl-11 col-lg-11 col-md-12 mx-auto separator">
                          <Row>
                            <div className="col-xl-6 col-lg-6 col-md-12  pt-4 pe-5 pb-3">
                              <h5 className="fw-500 fs-18  lh-32">
                                <span className="primary-color fw-500">✓</span>{" "}
                                {node2.BogoAppItem}
                              </h5>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12  pt-4 pe-5 pb-3">
                              <h5 className="fw-500 fs-18  lh-32 bogo-par">
                                ✗ {node2.IncentiveAppItem}
                              </h5>
                            </div>
                          </Row>
                        </div>
                      ))}
                  </>
                );
              })}
            </div>
          </section>
          <section className="bogobusiness d-block d-sm-none">
            <div className="col-xl-12 col-lg-12 col-md-12 py-5 px-4 busines-separator">
              <div className="col-xl-5 col-lg-5 card-body"></div>
              <div className="col-xl-6 col-lg-6 col-md-12  pt-4 px-4 pb-3">
                <img
                  src="../img/Bogo-About.svg"
                  alt="Logo About"
                  height="56"
                  width="96"
                />
              </div>
              {BusinessItem.map((node, index) => {
                return (
                  <>
                    {node?.BusinessItems?.length > 0 &&
                      node.BusinessItems.map((node2) => (
                        <div
                          className="col-xl-11 col-lg-11 col-md-12 mx-auto separator"
                          style={{
                            "border-top": "2px dashed #b1b1b1;",
                            "border-bottom": "none",
                          }}
                        >
                          <Row>
                            <div className="col-xl-6 col-lg-6 col-md-12  pt-4 px-4 pb-3">
                              <h5 className="fw-500 fs-18  lh-32">
                                <span className="primary-color fw-500">✓</span>{" "}
                                {node2.BogoAppItem}
                              </h5>
                            </div>
                          </Row>
                        </div>
                      ))}
                  </>
                );
              })}

              <div className="col-xl-11 col-lg-11 col-md-12 mx-auto">
                <Row>
                  <div className="col-xl-6 col-lg-6 col-md-12  pt-5 px-4 pb-3 align-self-center">
                    <h4 className="fw-bold fs-24">Other Incentive Apps</h4>
                  </div>
                </Row>
              </div>
              {BusinessItem.map((node, index) => {
                return (
                  <>
                    {node?.BusinessItems?.length > 0 &&
                      node.BusinessItems.map((node2) => (
                        <div className="col-xl-11 col-lg-11 col-md-12 mx-auto separator">
                          <Row>
                            <div className="col-xl-6 col-lg-6 col-md-12  pt-4 px-4 pb-3">
                              <h5 className="fw-500 fs-18  lh-32 bogo-par">
                                ✗ {node2.IncentiveAppItem}
                              </h5>
                            </div>
                          </Row>
                        </div>
                      ))}
                  </>
                );
              })}
            </div>
          </section>
          <section>
            <div className="col-xl-10 col-lg-12 col-md-12 pb-xl-5 px-4 my-4 mx-auto">
              <Row className="bg-light my-5">
                {BogoImageWidget.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-6 col-lg-6 col-md-12 ps-0 ">
                        <Player
                          src="../img/Merchants.mp4"
                          poster="../img/bogo-video.png"
                        >
                          <BigPlayButton position="center" />
                        </Player>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 py-4 pe-2 mt-2 ps-lg-5 ps-3  become-bogo">
                        <h5 className="fw-bold mb-4">
                          {node.BusinessTitleCTALinkTitle}
                        </h5>
                        <p className="bogo-par fs-18 mb-1">
                          {node.BusinessTitleCTALinkDescription}
                        </p>

                        <div className="Link-become pt-4">
                          <Link
                            to={node.BusinessTitleCTALinkLink}
                            className="fs-18 link-color pb-2"
                          >
                            {node.BusinessTitleCTALinkText}{" "}
                            <img
                              src="../img/arrow-right.svg"
                              alt="arrow right"
                              className="ms-3"
                            />
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Row>
              <Row className="text-center justify-content-center vector-img my-xl-5 py-xl-5">
                {BogoStep.map((node, index) => {
                  return (
                    <>
                      {node?.BusinessSteps?.length > 0 &&
                        node.BusinessSteps.map((node2, index3) => (
                          <>
                            {index3 === 0 || index3 === 1 || index3 === 2 ? (
                              <div className="col-xl-4 col-lg-4 col-md-6 mb-5">
                                <img
                                  src={node2.BusinessStepImage}
                                  alt="MoBile Payments"
                                  className="mb-5"
                                />
                                <h5 className="fw-500 bogo-muted fs-20 lh-26 pe-4">
                                  {node2.BusinessStepTitle}
                                </h5>
                              </div>
                            ) : (
                              <div className="col-xl-4 col-lg-4 col-md-6 mb-5 mt-4">
                                <img
                                  src={node2.BusinessStepImage}
                                  alt="MoBile Payments"
                                  className="mb-5"
                                />
                                <h5 className="fw-500 bogo-muted fs-20 lh-26 pe-4">
                                  {node2.BusinessStepTitle}
                                </h5>
                              </div>
                            )}
                          </>
                        ))}
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="business-h bg-light">
          <section>
            <Container className="me-0">
              <Row>
                {BogoBrochure.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-7 col-lg-5 col-md-12 py-5">
                        <h2 className="fx-bold mb-4">{node.BrochureTitle}</h2>
                        <h4 className="fs-bold primary-color fs-22">
                          {node.BrochureDescription}
                        </h4>
                        <form
                          onSubmit={handleSubmit}
                          name="Contact-client"
                          method="post"
                          data-netlify-recaptcha="true"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                        >
                          <input
                            type="hidden"
                            name="form-name"
                            value="Contact-client"
                          />

                          <Form.Group
                            className="mb-2 col-xl-6 col-lg-6 col-md-6 col-12"
                            controlId="exampleForm.ControlInput2"
                          >
                            <Form.Control
                              type="text"
                              style={{
                                "margin-top": "0.9rem",
                                background: "white",
                              }}
                              placeholder="Full Name"
                              id="firstname"
                              name="firstname"
                              onChange={handleChange}
                              value={formState.firstname}
                              required
                            />

                            <Form.Control
                              type="email"
                              style={{
                                "margin-top": "1rem",
                                background: "white",
                              }}
                              placeholder="Email"
                              id="email"
                              name="email"
                              onChange={handleChange}
                              value={formState.email}
                              required
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Recaptcha
                              ref={recaptchaRef}
                              sitekey="6LeUanMcAAAAAAk3U8ieboKe0uqx1LNFb8i4QvUi"
                              size="normal"
                              id="recaptcha-google"
                              onChange={() => setButtonDisabled(false)}
                            />
                          </Form.Group>
                          <Button
                            className="w-30 fs-14 fw-bold btn btn-primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </form>
                        {/* <div className="Link-become pt-5">
                        <Link to={node.BrochureCTALink} className="fs-18 link-color pb-2">
                          {node.BrochureCTAText}{" "}
                          <img
                            src="../img/arrow-right.svg"
                            alt="arrow right"
                            className="ms-3"
                          />
                        </Link>
                      </div>*/}
                      </div>
                      <div className="col-xl-5 col-lg-7 col-md-12 pe-0 ms-auto vector wb-right d-none d-sm-block">
                        <div className="row text-right">
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.BrochureImage}
                              alt="Iphone"
                              height="578"
                              className="nav-link pe-0"
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.SecondBrochureImage}
                              alt="Iphone"
                              height="578"
                              className="nav-link pe-0"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-7 col-md-12 pe-0 ms-auto vector wb-right d-block d-sm-none">
                        <div className="d-flex text-right">
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.BrochureImage}
                              alt="Iphone"
                              className="nav-link pe-0 become-bogo"
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 pe-0">
                            <img
                              src={node.SecondBrochureImage}
                              alt="Iphone"
                              className="nav-link pe-0 become-bogo"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Row>
            </Container>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query {
    BusinessItem: allBusinessJson(
      filter: { BusinessItems: { elemMatch: { BogoAppItem: { ne: null } } } }
    ) {
      nodes {
        BusinessItems {
          BogoAppItem
          IncentiveAppItem
        }
      }
    }
    BogoImageWidget: allBusinessJson(
      filter: { BusinessTitleCTALinkTitle: { ne: null } }
    ) {
      nodes {
        BusinessTitleCTALinkDescription
        BusinessTitleCTALinkImage
        BusinessTitleCTALinkText
        BusinessTitleCTALinkLink
        BusinessTitleCTALinkTitle
      }
    }
    BogoStep: allBusinessJson(
      filter: {
        BusinessSteps: { elemMatch: { BusinessStepTitle: { ne: null } } }
      }
    ) {
      nodes {
        BusinessSteps {
          BusinessStepImage
          BusinessStepTitle
        }
      }
    }

    BogoBrochure: allResourceJson(filter: { BrochureTitle: { ne: null } }) {
      nodes {
        BrochureCTALink
        BrochureCTAText
        BrochureDescription
        BrochureImage
        SecondBrochureImage
        BrochureTitle
      }
    }
  }
`;
