import React from "react";
import dateFormat, { masks } from "dateformat";
import { Store } from "react-notifications-component";

export function NotificationError({ message = "" }) {
  Store.addNotification({
    title: "Error!",
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function SortByField(list = [], name) {
  return list.sort(function (a, b) {
    return b[name] - a[name];
  });
}

export function NotificationSuccess({ message = "" }) {
  Store.addNotification({
    title: "Success!",
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function momentDate(a, format = "MM-DD-YYYY, h:mm A") {
  if (!a || !Date.parse(a)) return "";
  return dateFormat(a, format);
}

export const businessType = [
  {
    Name: "Dining",
    tagy: [
      "African",
      "Arabic",
      "American",
      "Asian",
      "AYCE",
      "Bakery",
      "BBQ",
      "Beer",
      "Brazilian",
      "Breakfast",
      "Brunch",
      "British",
      "Bubble Tea",
      "Buffet",
      "Burgers",
      "Cafe",
      "Cakes",
      "Caribbean",
      "Chinese",
      "Chocolate",
      "Coffee",
      "Creole",
      "Deli",
      "Desserts",
      "Drinks",
      "Eastern European",
      "Egyptian",
      "Fine Dining",
      "French",
      "German",
      "Gluten-Free",
      "Grills",
      "Greek",
      "Halal",
      "Healthy",
      "International",
      "Italian",
      "Indian",
      "Japanese",
      "Karaoke",
      "Korean",
      "Kosher",
      "Live Entertainment",
      "Mediterranean",
      "Mexican",
      "Middle Eastern",
      "Organic",
      "Pakistani",
      "Pasta",
      "Pastry",
      "Persian",
      "Pizza",
      "Portuguese",
      "Poutine",
      "Pub Food",
      "Salad",
      "Sandwiches",
      "Seafood",
      "Shawarma",
      "Smoothies",
      "Snacks",
      "Spanish",
      "Snacks",
      "South American",
      "Soups",
      "Sushi",
      "Tea",
      "Thai",
      "Turkish",
      "Vegan",
      "Vegetarian",
      "Vietnamese",
      "Steak",
    ],
  },
  {
    Name: "Retail",
    tagy: [
      "Accessories",
      "Audio and Video",
      "Beauty & Health",
      "Clothing",
      "Electronics",
      "Eyewear",
      "Footwear",
      "Furniture",
      "Fragrances",
      "Health and Nutrition",
      "Home Furnishings",
      "Kitchenwares",
      "Home Appliances",
      "Personal Care",
      "Pet Stores",
      "Services",
      "Sports",
      "Stationery",
      "Toys and Games",
      "Watches",
    ],
  },
  {
    Name: "Services",
    tagy: [
      "Automotive",
      "AC Maintenance",
      "Beauty",
      "Stylist",
      "Carpentry",
      "Chiropractic",
      "Counseling",
      "Cleaning",
      "Dry Cleaning",
      "Healing",
      "Laundry",
      "Makeup artistry",
      "Medical",
      "Pest Control",
      "Physical Therapy",
      "Rehabilitation",
      "Tailoring",
      "Upholstery",
    ],
  },
  {
    Name: "Spas & Salons",
    tagy: [
      "Barbershop",
      "Facial",
      "Hair Salon",
      "Hammam",
      "Nail Salon",
      "Medi-Spa",
      "Massage",
      "Makeup",
      "Moroccan Bath",
      "Wellness",
      "Whitening",
    ],
  },
  {
    Name: "Entertainment",
    tagy: [
      "Adventure",
      "Amusement",
      "Art Gallery",
      "Comedy",
      "Landmarks",
      "Live Entertainment",
      "Museums",
      "Music",
      "Performing Arts",
      "Sports",
      "Theatre",
      "Tourism",
    ],
  },
  { Name: "E-Commerce" },
  {
    Name: "Fitness",
    tagy: [
      "Group Training",
      "Gym",
      "Personal Training",
      "Private Gym",
      "Pilates",
      "Sports Training",
      "Studio",
      "Swimming",
      "Weight Loss",
      "Yoga",
    ],
  },
  {
    Name: "Hotels",
    tagy: ["Casino", "Hotel", "Motel", "Resort", "Retreat", "Staycation"],
  },
];
